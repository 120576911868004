<template>
  <div class="add-new-car">
    <title-bar
      submitBtn
      submitBtnTitle="Add car"
      title="Create car"
      @submitPressed="submitPressed"
    />
    <car-form :carObject="car" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CarForm from '@/views/Admin/Cars/components/CarForm.vue'

export default {
  components: {
    TitleBar,
    CarForm
  },
  data() {
    return {
      action: null,
      car: {
        name: '',
        description: '',
        registrationPlate: '',
        courier: { label: 'Not selected', id: null},
        color: { label: 'Not selected', id: null, disabled: true },
        size: { label: 'Not selected', id: null, disabled: true }
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  },
}
</script>
